/* 
  Restore Tailwind CSS classes that were overridden by global styles.
  These declarations reinstate Tailwind's utility classes with increased specificity
  to ensure they work as intended on landing pages.
*/

[class].space-y-4 > * + *,
body .space-y-4 > * + * {
  margin-top: 16px !important;
}

/* 
  Swiper.js scrollbar customization
  Styles for the custom scrollbar in swiper carousels
*/
.swiper-scrollbar {
  opacity: 1 !important;
  width: 8px !important;
  background-color: #e5e7eb !important;
}

.swiper-scrollbar-drag {
  width: 8px !important;
  background-color: #0021f8 !important;
  opacity: 1 !important;
}

.swiper-vertical > .swiper-scrollbar {
  right: 0 !important;
}

.swiper-vertical > .swiper-scrollbar {
  right: 0 !important;
  width: 8px !important;
}

.swiper-vertical .swiper-scrollbar-drag {
  width: 8px !important;
}
